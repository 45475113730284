import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  TextFieldProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { PasswordInput, PasswordInputProps } from './PasswordInput';

const Label = styled('label')(() => ({
  marginTop: 0,
  lineHeight: '16px',
  color: '#544F4F',
  fontSize: '12px',
  fontWeight: 400,
  display: 'block',
  marginBottom: '0px',
}));

const StyledInput = styled(
  React.forwardRef((props: TextFieldProps, ref: any) => {
    return (
      <TextField
        ref={ref}
        {...props}
        margin="normal"
        size="small"
        InputProps={{ ...props?.InputProps }}
        InputLabelProps={{ ...props?.InputLabelProps }}
      />
    );
  })
)(() => ({
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: '1px solid #544F4F1F',
    borderRadius: '32px',
    padding: '13px 20px',
    lineHeight: '24px',
    color: '#544F4F',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    boxSizing: 'border-box',
    input: {
      padding: '0',
      fontSize: '16px',
      fontWeight: 400,
    },
  },
}));

const StyledPasswordInput = styled(
  React.forwardRef((props: PasswordInputProps, ref: any) => {
    return (
      <PasswordInput
        ref={ref}
        formControlProps={{ margin: 'normal', size: 'small' }}
        disableUnderline={true}
        {...props}
      />
    );
  })
)(() => ({
  backgroundColor: 'transparent',
  border: '1px solid #544F4F1F',
  borderRadius: '32px',
  padding: '14px 14px 14px 20px',
  lineHeight: '24px',
  color: '#544F4F',
  width: '100%',
  fontSize: '16px',
  fontWeight: 400,
  boxSizing: 'border-box',
  '& input': {
    padding: '0',
    paddingTop: '2px',
    fontSize: '16px',
    fontWeight: 400,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000000',
  borderRadius: '60px',
  textTransform: 'none',
  height: '52px',
  fontWeight: 500,
  fontSize: '16px',
  background: '#19BABB',

  '&:hover': {
    background: '#19BABB95',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));

const BpIcon = styled('span')(() => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: '1px solid #544F4F59',
  'input:hover ~ &': {
    backgroundColor: '#e7e7e7;',
  },
  'input:disabled ~ &': {
    background: '#e7e7e7;',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#000',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#000',
  },
});

interface BpCheckboxProps {
  name: string;
  register: Function;
}

const BpCheckbox: React.FC<BpCheckboxProps> = ({ name, register }) => {
  return (
    <Checkbox
      {...register(name, { required: true })}
      sx={{
        paddingLeft: 0,
        '&:hover': { bgcolor: 'transparent' },
        paddingRight: (theme) => theme.spacing(0.5),
        '~ .MuiCheckbox-root': {
          backgroundColor: 'transparent',
        },
        '~ &': {
          backgroundColor: '#e7e7e7;',
        },
      }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
    />
  );
};

const loginFormSchema = z.object({
  username: z.string().min(2, { message: 'Minimum 2 chars' }),
  password: z.string().min(4, { message: 'Minimum 4 chars' }),
  rememberme: z.boolean(),
});

export const defaultLabels = {
  email_username: 'Digite seu e-mail...',
  remember_me: 'Lembre-me',
  forgot_password: 'Esqueceu sua senha?',
  signin_submit: 'Entrar',
  password: 'Digite sua senha...',
};
export type LoginFormValues = z.infer<typeof loginFormSchema>;
export type LoginFormProps = {
  onSubmit: (formValues: LoginFormValues) => Promise<void>;
  children?: React.ReactNode | React.ReactNode[];
  initialValues?: LoginFormValues;
  labels?: typeof defaultLabels;
};

const LoginForm = ({
  onSubmit,
  children,
  initialValues,
  labels = defaultLabels,
}: LoginFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginFormSchema),
    defaultValues: initialValues,
  });

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSubmit(onSubmit)(e);
      }}
    >
      <Label>E-mail</Label>
      <StyledInput
        fullWidth
        InputProps={{ disableUnderline: true }}
        variant="standard"
        style={{
          marginBottom: '24px',
          marginTop: '0.5vw',
        }}
        {...register('username', {
          required: true,
          minLength: 2,
          maxLength: 50,
        })}
        type="email"
        placeholder={labels.email_username}
        error={!!errors.username}
        helperText={errors.username?.message}
      />
      <Label>Senha</Label>
      <StyledPasswordInput
        {...register('password', {
          required: true,
          minLength: 2,
          maxLength: 50,
        })}
        style={{
          marginTop: '0.5vw',
        }}
        id="password-login"
        placeholder={labels?.password}
        error={errors?.password?.message}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle2"
          color="GrayText"
          sx={{
            fontWeight: 400,
            fontSize: '12px',
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <BpCheckbox name="rememberme" register={register} />
          {labels.remember_me}
        </Typography>
      </div>
      {errors.root && (
        <Box sx={{ mt: 1 }}>
          <FormHelperText error>{errors.root.message}</FormHelperText>
        </Box>
      )}
      {children}

      <Box sx={{ margin: '20px 50px 5px 50px' }}>
        <StyledButton
          disableElevation
          disabled={isSubmitting}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          color="secondary"
        >
          {labels.signin_submit}
        </StyledButton>
      </Box>
    </form>
  );
};

export default LoginForm;
