import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="25"
    viewBox="0 0 20 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={props.style}
  >
    <g clipPath="url(#clip0_374_102)">
      <path
        d="M16.4883 8.90137C16.91 9.378 17.166 10.0031 17.166 10.6894C17.166 12.1859 15.9524 13.3995 14.4559 13.3995C13.7697 13.3995 13.1445 13.1434 12.6679 12.7224"
        stroke={props.color ? props.color : '#969696'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83301 21.3476L2.50014 12.7926L6.81125 8.48145"
        stroke={props.color ? props.color : '#969696'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1104 21.3457H4.33319"
        stroke={props.color ? props.color : '#969696'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.56738 4.8035L6.77755 3.01367"
        stroke={props.color ? props.color : '#969696'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.88754 13.7362C7.59696 12.6161 6.77751 10.9674 6.77751 9.1244C6.77751 5.74944 9.51328 3.01367 12.8882 3.01367C14.7312 3.01367 16.3799 3.83312 17.5 5.12371L8.88754 13.7362Z"
        stroke={props.color ? props.color : '#969696'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_374_102">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(-1 0 0 1 20 2.18066)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
