import { styled } from '@mui/material/styles';
import { Grid, Link } from '@mui/material';

const LoginModalContainer = styled(Grid)(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  zIndex: 9999,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100vh',
  background: 'rgba(0, 0, 0, 0.50)',

  '&.is-login-page': {
    backgroundImage: 'url("/images/bamboo-login.png")',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    zIndex: 0,
  },
}));

export const CloseContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '20px 20px 0',
  opacity: 1,
  position: 'absolute',
  top: '-50px',
  right: '-70px',
  zIndex: 99,
  [theme.breakpoints.down('md')]: {
    top: '-30px',
    right: '-45px',
  },
  [theme.breakpoints.down('sm')]: {
    top: '-30px',
    right: '-30px',
  },
}));

export const IconButton = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: '1px',
  fontSize: 'inherit',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: 1,
  borderRadius: '9999px',
}));

const FormLoginContainer = styled(Grid)(({ theme }) => ({
  width: '480px',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '32px',
  padding: '40px 60px',
  gap: '20px',
  backgroundColor: '#fff',

  transition: 'all 0.1s ease 0s',

  '&.is-login-page': {
    marginTop: '76px',
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
    borderRadius: '0',
    padding: '16px 30px',
    gap: '26px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    borderRadius: '0',
    padding: '16px',
  },
}));

const SocialContainer = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
}));

const Separator = styled('div')(() => ({
  background: '#544F4F1F',
  height: '1px',
  width: '100%',
}));

const HeaderContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    gap: '6px',
  },
}));

const FormTitle = styled('h1')(({ theme }) => ({
  fontSize: '30px',
  lineHeight: '30px',
  fontWeight: 500,
  color: '#414141',
  margin: '0',
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    fontSize: '1.25rem',
    lineHeight: '1.25rem',
  },
}));

const FormDescription = styled('p')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 500,
  color: '#544F4F',
  textAlign: 'center',
  margin: '0',

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
  },
}));

const FooterContainer = styled('div')(({ theme }) => ({
  alignSelf: 'flex-end',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '30px',

  [theme.breakpoints.down('md')]: {
    gap: '12px',
  },
}));

const ForgetPasswordLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  border: '0',
  cursor: 'pointer',
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
  color: '#544F4F',
  borderBottom: '1px solid #544F4F59',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.75rem',
  },
}));

const NavigateThroughContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '2px',
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 400,
  color: '#544F4F',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    gap: '2px',
    fontSize: '0.8125rem',
  },
}));

const NavigateThrough = styled('span')(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '14px',
  fontWeight: 500,
  color: '#544F4F',
  borderBottom: '1px solid #544F4F',
  cursor: 'pointer',

  [theme.breakpoints.down('md')]: {
    fontSize: '0.8125rem',
  },
}));

export {
  LoginModalContainer,
  FormLoginContainer,
  SocialContainer,
  Separator,
  HeaderContainer,
  FormTitle,
  FormDescription,
  FooterContainer,
  ForgetPasswordLink,
  NavigateThroughContainer,
  NavigateThrough,
};
