import { red, common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { montserrat } from '@/themes/fonts';

// Create a mui v5 theme instance.
export const muiTheme = createTheme({
  typography: {
    ...montserrat.style,
  },
  palette: {
    error: {
      main: red.A400,
    },
    secondary: {
      main: common.black,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 412,
      md: 768,
      lg: 1026,
      xl: 1400,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        asterisk: { color: 'red' },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export type Theme = typeof muiTheme;
