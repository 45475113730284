import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  TextFieldProps,
  FormHelperText,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { PasswordInput, PasswordInputProps } from './PasswordInput';
import { emailValidation } from '@bamboo/ts-utils';

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  width: '100%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    gap: '8px',
  },
}));

const FieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  width: '100%',
}));

const Label = styled('label')(() => ({
  marginTop: 0,
  lineHeight: '16px',
  color: '#544F4F',
  fontSize: '12px',
  fontWeight: 400,
  display: 'block',
  marginBottom: '0px',
}));

const StyledInput = styled(
  React.forwardRef((props: TextFieldProps, ref: any) => {
    return (
      <TextField
        ref={ref}
        {...props}
        margin="normal"
        size="small"
        inputProps={{ disableUnderline: true, ...props?.inputProps }}
        InputLabelProps={{
          ...props?.InputLabelProps,
        }}
      />
    );
  })
)(({ theme }) => ({
  marginTop: '8px',
  padding: '0',
  '& .MuiInputBase-root': {
    backgroundColor: 'transparent',
    border: '1px solid #544F4F1F',
    borderRadius: '32px',
    padding: '13px 20px',
    lineHeight: '24px',
    color: '#544F4F',
    width: '100%',
    fontSize: '16px',
    fontWeight: 400,
    height: '52px',
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      fontSize: '8px',
    },
  },
  '& input': {
    padding: '0',
    paddingTop: '2px',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));

const StyledPasswordInput = styled(
  React.forwardRef((props: PasswordInputProps, ref: any) => {
    return (
      <PasswordInput
        ref={ref}
        formControlProps={{ margin: 'normal', size: 'small' }}
        disableUnderline={true}
        {...props}
      />
    );
  })
)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: '1px solid #544F4F1F',
  borderRadius: '32px',
  padding: '13px 20px',
  lineHeight: '24px',
  color: '#544F4F',
  width: '100%',
  fontSize: '16px',
  fontWeight: 400,
  boxSizing: 'border-box',
  marginTop: '8px',
  height: '52px',

  '& input': {
    paddingTop: '2px',
    paddingLeft: '0px',

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '60px',
  textTransform: 'none',
  height: '52px',
  fontWeight: 500,
  fontSize: '16px',
  background: '#19BABB',

  '&:hover': {
    backgroundColor: '#19BABB95',
  },

  '&:disabled': {
    backgroundColor: '#19BABB50',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '0.875rem',
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  border: '1px solid #544F4F59',
  'input:hover ~ &': {
    backgroundColor: '#e7e7e7;',
  },
  'input:disabled ~ &': {
    background: '#e7e7e7;',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#26bebf',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#26bebf',
  },
});

const BpCheckbox = (props: any) => {
  const { error } = props;
  return (
    <>
      <Checkbox
        sx={{
          paddingLeft: 0,
          paddingRight: (theme) => theme.spacing(0.5),
          '&:hover': { bgcolor: 'transparent' },
        }}
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </>
  );
};

const loginFormSchema = z.object({
  userFirstName: z.string(),
  userLastName: z.string(),
  email: z.string(),
  password: z.string(),
  confirm: z.string(),
  readingTerms: z.boolean(),
});

export const DefaultLabels = {
  email: 'Email',
  password: 'Senha',
  password_confirm: 'Confirmar senha',
  user_first_name: 'Digite seu nome',
  user_last_name: 'Digite seu sobrenome',
  reading_terms: 'Li e concordo com o(s)',
  terms: 'termos e condições do site',
  onboarding_submit: 'Criar conta',
  password_error:
    'A senha deve conter no mínimo 8 caracteres e ao menos uma letra e um número',
  email_error: 'Email inválido',
};

export type OnboardingFormValues = z.infer<typeof loginFormSchema>;
export type OnboardingFormProps = {
  onSubmit: (formValues: OnboardingFormValues) => Promise<void>;
  children?: React.ReactNode | React.ReactNode[];
  initialValues?: OnboardingFormValues;
  labels?: typeof DefaultLabels;
  Link?: React.ElementType<{ href: any }>;
};

const OnboardingForm = ({
  onSubmit,
  children,
  initialValues,
  labels = DefaultLabels,
  Link,
}: OnboardingFormProps) => {
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<OnboardingFormValues>({
    defaultValues: initialValues,
  });

  const password = watch('password');

  const validateEmail = React.useCallback((value: string) => {
    if (!value) {
      return;
    }
    if (!emailValidation(value)) {
      return labels?.email_error;
    }
    return;
  }, []);

  const validateTerms = React.useCallback((value: boolean) => {
    if (!value) {
      return 'Para continuar você deve ler e aceitar os termos de uso e privacidade.';
    }
  }, []);

  const validatePassword = React.useCallback((value: any) => {
    if (!value) {
      return;
    }
    const hasDigit = value.match(/[0-9]/gi);
    const hasNonDigit = value.match(/[^0-9]/gi);
    if (!hasDigit || !hasNonDigit || value.length < 8) {
      return labels?.password_error;
    }
    return;
  }, []);

  const validatePasswordConfirm = React.useCallback(
    (value: string) => {
      if (!value || !password) {
        return;
      }
      if (password !== value) {
        return 'Senhas diferentes';
      }
      return;
    },
    [password]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <FieldContainer>
          {/* First Name */}
          <Label>Nome</Label>
          <StyledInput
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant="standard"
            {...register('userFirstName', {
              required: true,
              minLength: 2,
              maxLength: 50,
            })}
            type="text"
            placeholder={labels.user_first_name}
            error={!!errors.userFirstName?.message}
            helperText={errors.userFirstName?.message}
          />
        </FieldContainer>

        <FieldContainer>
          {/* Sobrenome */}
          <Label>Sobrenome</Label>
          <StyledInput
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant="standard"
            {...register('userLastName', {
              required: true,
              minLength: 2,
              maxLength: 50,
            })}
            type="text"
            placeholder={labels.user_last_name}
            error={!!errors.userLastName?.message}
            helperText={errors.userLastName?.message}
          />
        </FieldContainer>
      </Row>

      {/* Email */}
      <Label>E-mail</Label>
      <StyledInput
        fullWidth
        InputProps={{ disableUnderline: true }}
        type={'email'}
        variant="standard"
        {...register('email', {
          required: true,
          minLength: 2,
          maxLength: 50,
          validate: validateEmail,
        })}
        helperText={errors?.email?.message}
        error={!!errors?.email?.message}
        placeholder={labels.email}
      />

      <Row>
        <FieldContainer>
          {/* Senha */}
          <Label>Senha</Label>
          <StyledPasswordInput
            {...register('password', {
              required: true,
              maxLength: 50,
              validate: validatePassword,
            })}
            id="password"
            placeholder={labels.password}
            error={errors?.password?.message}
          />
        </FieldContainer>
        <FieldContainer>
          <Label>Repetir Senha</Label>
          <StyledPasswordInput
            {...register('confirm', {
              required: true,
              maxLength: 50,
              validate: validatePassword,
            })}
            id="password"
            placeholder={labels.password_confirm}
            error={errors?.confirm?.message}
          />
        </FieldContainer>
      </Row>
      <div
        style={{
          marginLeft: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle2"
          color="GrayText"
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <BpCheckbox {...register('readingTerms')} />
          <div style={{ fontSize: '12px' }}>
            {labels?.reading_terms}&nbsp;
            <LinkComponent
              Link={Link}
              style={{
                textDecoration: 'none',
                color: '#19BABB',
                borderBottom: '1px solid #19BABB',
                fontSize: '12px',
              }}
              href="/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              {labels?.terms}
            </LinkComponent>
          </div>
        </Typography>
      </div>
      {errors.root && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{errors.root.message}</FormHelperText>
        </Box>
      )}
      {children}

      <Box sx={{ margin: '20px 50px 5px 50px' }}>
        <StyledButton
          disableElevation
          fullWidth
          type="submit"
          variant="contained"
          color="secondary"
        >
          {labels.onboarding_submit}
        </StyledButton>
      </Box>
    </form>
  );
};

type LinkComponentProps = {
  Link?: React.ElementType<any>;
  children: React.ReactNode;
} & React.HTMLProps<HTMLAnchorElement>;

const LinkComponent = (props: LinkComponentProps) => {
  const { Link, ...others } = props;
  if (!!Link) {
    return <Link {...others} />;
  }
  return <a {...others} />;
};

export default OnboardingForm;
