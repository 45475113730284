import { createStore } from 'zustand/vanilla';
import { useStore } from 'zustand';

export type Plan = {
  planID: number;
  name: string;
  level: number;
  annual: boolean;
  enterprise: boolean;
  price: number;
  clipsLimit: number;
  enable4K: boolean;
  enableTvLicense: boolean;
  ilimitedLicenses: boolean;
};

export type UserSubscriptionInfo = {
  plan: {
    planID: number;
    name: string;
    level: number;
    annual: boolean;
  };
  clipsRemaining: number;
  subscription: {
    subscriptionID: string;
    status: string;
    createdAt: string;
  };
  period: {
    currentPeriodStartedAt: string | null;
    currentPeriodEndAt: string | null;
  };
  card: {
    creditCardLastFour: string;
    creditCardBrand: string;
  };
  features: {
    clipsLimit: number;
    enable4K: boolean;
    enableTvLicense: boolean;
    ilimitedLicenses: boolean;
  };
};
export type SubscriptionBills = {
  billID: string;
  amount: string;
  createdAt: string;
  status: 'pending' | 'paid' | 'refunded' | 'canceled';
  refundedAt: string | null;
  canceledAt: string | null;
}[];

type InitialState = {
  plan: Plan | null;
  subscriptionInfo: UserSubscriptionInfo | null;
  bills: SubscriptionBills | null;
};

const initialData: InitialState = {
  plan: null,
  subscriptionInfo: null,
  bills: null,
};

let store = createStore<InitialState>(() => initialData);

export const setUserPlan = (plan: Plan | null) => {
  store.setState({ plan });
};
export const setUserSubscriptionInfo = (
  subscriptionInfo: UserSubscriptionInfo | null
) => {
  store.setState({ subscriptionInfo });
};
export const setSubscriptionBills = (bills: SubscriptionBills) => {
  store.setState({ bills });
};

export function hydrate(initialData: InitialState) {
  store = createStore<InitialState>(() => initialData);
}

export function reset(
  newValue?: (current: InitialState) => Partial<InitialState>
) {
  const current = store.getState();
  store.setState({ ...initialData, ...(newValue?.(current) ?? {}) });
}

export function state() {
  return store.getState();
}

export const useUserPlan = () => useStore(store, (s) => s);
