import { makeApi, Zodios, type ZodiosOptions } from '@zodios/core';
import { z } from 'zod';

const analyticsPageview_Body = z
  .object({ fingerprint: z.string(), path: z.string() })
  .passthrough();
const uploadedMediaAuth_Body = z
  .object({ mediaURL: z.string().nullable(), usdoID: z.number().nullable() })
  .partial()
  .passthrough();
const authPasswordRecover_Body = z
  .object({
    AccessModel: z.string().nullish(),
    applID: z.string(),
    email: z.string(),
    resetURL: z.string().nullish(),
  })
  .passthrough();
const authPasswordReset_Body = z
  .object({ password: z.string(), verification: z.string() })
  .passthrough();
const authLogin_Body = z
  .object({
    applID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    email: z.string(),
    ip: z.string(),
    location: z.string(),
    password: z.string(),
    rememberMe: z.boolean(),
  })
  .passthrough();
const authEmailTokenAuth_Body = z
  .object({ applID: z.string(), email: z.string() })
  .passthrough();
const authEmailTokenSignin_Body = z
  .object({
    acveID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    ip: z.string(),
    location: z.string(),
    verification: z.string(),
  })
  .passthrough();
const billingInformationCreate_Body = z
  .object({
    billingAddress: z
      .object({
        cep: z.string(),
        city: z.string(),
        neighborhood: z.string(),
        state: z.string(),
        street: z.string(),
        streetNumber: z.string(),
      })
      .passthrough(),
    billingPhone: z
      .object({ ddd: z.string(), number: z.string() })
      .passthrough(),
    companyName: z.string().nullish(),
    dob: z.string().nullish(),
    documentNumber: z.string(),
    documentType: z.string(),
    email: z.string(),
    name: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartItem = z
  .object({ prodID: z.string() })
  .passthrough();
const cartCreate_Body = z
  .object({
    cartItems: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_CartItem
    ),
    session: z.array(z.number()).nullish(),
    userID: z.string().nullish(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_VariationOption = z
  .object({
    name: z.string(),
    pcopID: z.number(),
    povaID: z.number(),
    price: z.number(),
    priceMode: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation =
  z
    .object({
      caprID: z.number(),
      license: z.array(z.string()),
      price: z.number(),
      prodID: z.string(),
      sku: z.string(),
      slug: z.string(),
      thumbnailImageUrl: z.string(),
      title: z.string(),
      variations: z.array(
        github_com_bamboo_stock_ecommerce_backend_service_VariationOption
      ),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation =
  z
    .object({
      cartID: z.string(),
      code: z.string(),
      priceRules: z
        .object({
          discountAmount: z.string().nullable(),
          discountPercentage: z.string().nullable(),
        })
        .partial()
        .passthrough(),
      voucID: z.number(),
    })
    .passthrough();
const cartVoucherDelete_Body = z
  .object({ cartID: z.string(), voucher: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_RemovedCartItem = z
  .object({
    reason: z.string(),
    resourceID: z.string(),
    resourceLabel: z.string(),
    resourceType: z.string(),
  })
  .passthrough();
const cartCreateCheckout_Body = z
  .object({
    biinID: z.string().nullish(),
    info: z
      .object({
        companyName: z.string().nullable(),
        orderOfService: z.string().nullable(),
        projectName: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    userID: z.string().nullish(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Category = z
  .object({
    cateID: z.string(),
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    ordination: z.number().nullish(),
    parentID: z.string().nullish(),
    slug: z.string(),
    thumbnail: z.string().nullish(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilteredClipCategories =
  z
    .object({
      cateID: z.string(),
      description: z.string(),
      disabled: z.boolean(),
      image: z.string().nullish(),
      name: z.string(),
      ordination: z.number().nullish(),
      parentID: z.string().nullish(),
      slug: z.string(),
      thumbnail: z.string().nullish(),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ClipCategory = z
  .object({
    cateID: z.number(),
    description: z.string(),
    name: z.string(),
    order: z.number(),
    slug: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist = z
  .object({
    description: z.string(),
    name: z.string(),
    plliID: z.number(),
    slug: z.string(),
    sortOrder: z.number(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Tag = z
  .object({ name: z.string(), tagID: z.number(), slug: z.string().nullish() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Clip = z
  .object({
    categories: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipCategory
    ),
    createdAt: z.string(),
    description: z.string(),
    filmID: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    license: z.array(z.string()),
    makerAvatar: z.string(),
    makerDisplayName: z.string(),
    makerName: z.string(),
    makerSlug: z.string(),
    playlists: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist
    ),
    price: z.number(),
    prinID: z.number(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    tags: z.array(github_com_bamboo_stock_ecommerce_backend_service_Tag),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();

const github_com_bamboo_stock_ecommerce_backend_service_ClipV2 = z
  .object({
    makerDisplayName: z.string(),
    makerSlug: z.string(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();

const github_com_bamboo_stock_ecommerce_backend_service_Clip_CategoriesV2 =
  z.object({
    parentName: z.string(),
    parentOrder: z.number(),
    childName: z.string(),
    childSlug: z.string(),
  });

const github_com_bamboo_stock_ecommerce_backend_service_Get_ClipV2 = z
  .object({
    makerDisplayName: z.string(),
    makerSlug: z.string(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    watermarkPreviewURL: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    license: z.array(z.string()),
    tags: z.array(
      z.object({
        name: z.string(),
        slug: z.string(),
      })
    ),
  })
  .passthrough();

const github_com_bamboo_stock_ecommerce_backend_service_Variation = z
  .object({
    displaySettings: z.object({ widget: z.string() }).passthrough(),
    name: z.string(),
    options: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_VariationOption
    ),
    prcoID: z.number(),
    required: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_CreditCard = z
  .object({
    billingAddress: z
      .object({
        city: z.string(),
        country: z.string(),
        name: z.string(),
        neighborhood: z.string(),
        state: z.string(),
        street: z.string(),
        streetNumber: z.string(),
        zipcode: z.string(),
      })
      .passthrough(),
    brand: z.string(),
    crcaID: z.string(),
    createdAt: z.string(),
    expiresAt: z.string(),
    name: z.string(),
    provider: z.string(),
    providerCard: z.string(),
    userID: z.string(),
  })
  .passthrough();
const creditCardsCreate_Body = z
  .object({
    billingAddress: z
      .object({
        city: z.string(),
        country: z.string(),
        name: z.string(),
        neighborhood: z.string(),
        state: z.string(),
        street: z.string(),
        streetNumber: z.string(),
        zipcode: z.string(),
      })
      .passthrough(),
    brand: z.string(),
    expiresAt: z.string(),
    name: z.string(),
    provider: z.string(),
    providerCard: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation =
  z
    .object({
      configurationName: z.string(),
      optionName: z.string(),
      povaID: z.number(),
      prcoID: z.number(),
    })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableClip = z
  .object({
    caprID: z.number(),
    checID: z.number().nullish(),
    createdAt: z.string(),
    downID: z.number().nullish(),
    downloadCount: z.number(),
    downloadUrl: z.string().nullish(),
    maxDownload: z.number(),
    paymentStatus: z.string(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    state: z.string(),
    status: z.string(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    usdoID: z.number().nullish(),
    userID: z.string(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation
    ),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FavoriteProduct = z
  .object({
    createdAt: z.string(),
    faprID: z.number(),
    prodID: z.string(),
    userID: z.string(),
  })
  .passthrough();
const filmmakerApplicationCreate_Body = z
  .object({
    clientID: z.string(),
    email: z.string(),
    name: z.string(),
    nationality: z.string(),
    phone: z.string(),
    showcase: z.string(),
    socialMedia: z.string(),
    state: z.string(),
    surname: z.string(),
    website: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense =
  z
    .object({ amountSold: z.number(), soldAt: z.string().nullish() })
    .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_ProductsSold = z
  .object({
    price: z.number(),
    prodID: z.string(),
    sku: z.string(),
    slug: z.string(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    title: z.string(),
    variations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_VariationOption
    ),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilmmakerSales = z
  .object({
    checID: z.number(),
    createdAt: z.string(),
    filmID: z.string(),
    products: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_ProductsSold
    ),
  })
  .passthrough();
const marketplacePostbackPagarmev5_Body = z
  .object({
    account: z.object({ id: z.string(), name: z.string() }).passthrough(),
    data: z
      .object({
        charges: z.array(
          z
            .object({
              code: z.string(),
              id: z.string(),
              last_transaction: z
                .object({
                  created_at: z.string(),
                  gateway_response: z
                    .object({ code: z.string() })
                    .passthrough(),
                  id: z.string(),
                  operation_type: z.string(),
                  status: z.string(),
                  success: z.boolean(),
                  transaction_type: z.string(),
                  updated_at: z.string(),
                })
                .passthrough(),
              paid_at: z.string(),
              status: z.string(),
            })
            .passthrough()
        ),
        code: z.string(),
        id: z.string(),
        status: z.string(),
      })
      .passthrough(),
    id: z.string(),
    type: z.string(),
  })
  .passthrough();
const jobApplicationCreate_Body = z
  .object({
    city: z.string(),
    clientID: z.string(),
    cv: z.string(),
    email: z.string(),
    name: z.string(),
    phone: z.string(),
    socialMedia: z.string(),
    state: z.string(),
    surname: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_PaymentMethod = z
  .object({ description: z.string(), name: z.string() })
  .passthrough();
const marketplacePayment_Body = z
  .object({
    biinID: z.string(),
    checID: z.number(),
    crcaID: z.string().nullish(),
    cvv: z.string().nullish(),
    method: z.string(),
    referenceKey: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_MarketplaceRecipient = z
  .object({
    bankAccount: z.string(),
    bankAccountDigit: z.string(),
    bankAccountHolderName: z.string(),
    bankAgency: z.string(),
    bankAgencyDigit: z.string(),
    bankCode: z.string(),
    bankDocumentNumber: z.string(),
    bankDocumentType: z.string(),
    bankType: z.string(),
    createdAt: z.string(),
    createdBy: z.string(),
    deletedAt: z.string().nullish(),
    mareID: z.string(),
    method: z.string(),
    methodID: z.string(),
    pixKey: z.string().nullish(),
    transferDay: z.number(),
    transferInterval: z.string(),
  })
  .passthrough();
const marketplaceRecipientCreate_Body = z
  .object({
    bankAccount: z.string(),
    bankAccountDigit: z.string(),
    bankAccountHolderName: z.string(),
    bankAgency: z.string(),
    bankAgencyDigit: z.string(),
    bankCode: z.string(),
    bankDocumentNumber: z.string(),
    bankDocumentType: z.string(),
    bankType: z.string(),
    pixKey: z.string().nullish(),
    transferDay: z.number(),
    transferInterval: z.string(),
  })
  .passthrough();
const marketplaceUserCreate_Body = z
  .object({
    address: z
      .object({
        address: z.string(),
        city: z.string(),
        complement: z.string(),
        zipCode: z.string(),
      })
      .passthrough(),
    documentNumber: z.string(),
    documentType: z.string(),
    holderName: z.string(),
  })
  .passthrough();
const authCreateAccount_Body = z
  .object({
    applID: z.string(),
    coord: z.string().nullish(),
    deviceID: z.string(),
    deviceName: z.string(),
    email: z.string(),
    ip: z.string(),
    location: z.string(),
    name: z.string(),
    password: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Playlist = z
  .object({
    description: z.string(),
    image: z.string().nullish(),
    name: z.string(),
    parentID: z.number().nullish(),
    plliID: z.number(),
    shortDescription: z.string(),
    slug: z.string(),
    sortOrder: z.number().nullish(),
    thumbnail: z.string().nullish(),
    visible: z.boolean(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_Authorization = z
  .object({ name: z.string(), releaseType: z.string(), url: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductListing =
  z
    .object({
      approval: z
        .object({
          apprID: z.string(),
          approved: z.boolean(),
          createdAt: z.string(),
          prinID: z.number(),
          reason: z.string(),
          status: z.string(),
        })
        .passthrough()
        .nullish(),
      product: z
        .object({
          address: z
            .object({
              address: z.string().nullable(),
              city: z.string().nullable(),
              country: z.string().nullable(),
              country_short: z.string().nullable(),
              lat: z.number().nullable(),
              lng: z.number().nullable(),
              name: z.string().nullable(),
              place_id: z.string().nullable(),
              state: z.string().nullable(),
              state_short: z.string().nullable(),
            })
            .partial()
            .passthrough(),
          authorizations: z.array(
            github_com_bamboo_stock_ecommerce_backend_service_Authorization
          ),
          categories: z.array(z.number()),
          createdAt: z.string(),
          description: z.string().nullish(),
          hdUrl: z.string(),
          information: z
            .object({
              codecName: z.string(),
              duration: z.number(),
              framerate: z.string(),
              height: z.number(),
              width: z.number(),
            })
            .passthrough(),
          license: z.array(z.string()),
          pendingMedia: z.boolean(),
          prinID: z.number().nullish(),
          prodID: z.string().nullish(),
          tagNames: z.array(z.string()),
          thumbnailImageUrl: z.string(),
          thumbnailVideoUrl: z.string(),
          title: z.string().nullish(),
          ultraHd4kUrl: z.string(),
          updatedAt: z.string(),
          upmeID: z.string(),
          visible: z.boolean(),
          watermarkPreviewUrl: z.string(),
        })
        .passthrough(),
    })
    .passthrough();
const productCreate_Body = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string(),
    license: z.array(z.string()),
    tagNames: z.array(z.string()),
    title: z.string(),
    upmeID: z.string(),
  })
  .passthrough();
const productUpdate_Body = z
  .object({
    address: z
      .object({
        address: z.string().nullable(),
        city: z.string().nullable(),
        country: z.string().nullable(),
        country_short: z.string().nullable(),
        lat: z.number().nullable(),
        lng: z.number().nullable(),
        name: z.string().nullable(),
        place_id: z.string().nullable(),
        state: z.string().nullable(),
        state_short: z.string().nullable(),
      })
      .partial()
      .passthrough(),
    authorizations: z.array(
      github_com_bamboo_stock_ecommerce_backend_service_Authorization
    ),
    categories: z.array(z.number()),
    description: z.string(),
    license: z.array(z.string()),
    tagNames: z.array(z.string()),
    title: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_GeoLocationView = z
  .object({
    address: z
      .object({
        cep: z.string().nullish(),
        city: z.string(),
        complement: z.string().nullish(),
        coordinate: z.string(),
        country: z.string(),
        district: z.string().nullish(),
        number: z.string().nullish(),
        state: z.string(),
        stateAcronym: z.string(),
        street: z.string().nullish(),
      })
      .passthrough(),
    addressFormatted: z.string(),
  })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_PendingTerm = z
  .object({ slug: z.string(), termID: z.number(), title: z.string() })
  .passthrough();
const github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia = z
  .object({
    deletedAt: z.string().nullish(),
    hdURL: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    ultraHd4kURL: z.string(),
    uploadedAt: z.string(),
    uploadedBY: z.string(),
    upmeID: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const uploadedMediaCreate_Body = z
  .object({
    hdURL: z.string(),
    information: z
      .object({
        codecName: z.string(),
        duration: z.number(),
        framerate: z.string(),
        height: z.number(),
        width: z.number(),
      })
      .passthrough(),
    thumbnailImageURL: z.string(),
    thumbnailVideoURL: z.string(),
    ultraHd4kURL: z.string(),
    watermarkPreviewURL: z.string(),
  })
  .passthrough();
const usersGetProfileMe_Body = z
  .object({
    avatar: z.string().nullable(),
    filmmaker: z
      .object({
        description: z.string().nullable(),
        displayName: z.string().nullable(),
        headerImage: z.string().nullable(),
      })
      .partial()
      .passthrough()
      .nullable(),
    info: z.object({}).partial().passthrough().nullable(),
    name: z.string().nullable(),
  })
  .partial()
  .passthrough();

const createCreditCardV2_Body = z.object({
  holderName: z.string(),
  cardExpirationMonth: z.string(),
  cardExpirationYear: z.string(),
  cardNumber: z.string(),
  cardCvv: z.string(),
  cardBrand: z.string(),
});
const createCreditCardV2_Response = z.object({
  data: z.object({
    kind: z.string(),
    item: z.object({
      crcaID: z.string(),
      userID: z.string(),
      name: z.string(),
      brand: z.string(),
      provider: z.string(),
      providerCard: z.string(),
      expiresAt: z.string(),
      createdAt: z.string(),
    }),
  }),
});

const getCredtCardsV2_Response = z.object({
  data: z.object({
    kind: z.string(),
    items: z.array(
      z.object({
        crcaID: z.string(),
        userID: z.string(),
        name: z.string(),
        brand: z.string(),
        provider: z.string(),
        providerCard: z.string(),
        expiresAt: z.string(),
        createdAt: z.string(),
      })
    ),
    currentItemCount: z.number(),
    itemsPerPage: z.number(),
    startIndex: z.number(),
    totalItems: z.number(),
    pageIndex: z.number(),
    totalPages: z.number(),
  }),
});

const createSubscription_Body = z.object({
  planID: z.number(),
  creditCardID: z.string(),
});
const createSubscription_Response = z.object({
  data: z.object({
    kind: z.string(),
    item: z.object({
      subscriptionID: z.string(),
      planID: z.number(),
      status: z.string(),
      createdAt: z.string(),
      canceledAt: z.null().nullable(),
      customerID: z.string(),
    }),
  }),
});
const changeSubscription_Body = createSubscription_Body;
const changeSubscription_Response = createSubscription_Response;

const purchaseLicenses_Body = z.object({
  // list of the clips ids
  products: z.array(z.string()),
  creditCardID: z.string(),
});
const purchaseLicenses_Response = z.object({
  data: z.object({
    kind: z.string(),
    item: z.object({
      licenses: z.array(
        z.object({
          licenseID: z.string(),
          userID: z.string(),
          productID: z.string(),
          createdAt: z.string(),
        })
      ),
      bill: z.object({
        billID: z.string(),
        status: z.string(),
        amount: z.string(),
      }),
    }),
  }),
});

const userPlanSubscription_schema = z.object({
  planID: z.number(),
  name: z.string(),
  status: z.string(),
  createdAt: z.string(),
  subscriptionID: z.string(),
  level: z.number(),
  annual: z.boolean(),
  price: z.number(),
  enterprise: z.boolean(),
  clipsLimit: z.number(),
  enable4K: z.boolean(),
  enableTvLicense: z.boolean(),
  ilimitedLicenses: z.boolean(),
});

const planData = z.object({
  planID: z.number(),
  name: z.string(),
  level: z.number(),
  annual: z.boolean(),
  enterprise: z.boolean(),
  price: z.number(),
  clipsLimit: z.number(),
  enable4K: z.boolean(),
  enableTvLicense: z.boolean(),
  ilimitedLicenses: z.boolean(),
});

const getPlanByID_Response = z.object({
  data: z.object({
    kind: z.string(),
    item: planData,
  }),
});
const listPlans_Response = z.object({
  data: z.object({
    kind: z.string(),
    items: z.array(planData),
    currentItemCount: z.number(),
    itemsPerPage: z.number(),
    startIndex: z.number(),
    totalItems: z.number(),
    pageIndex: z.number(),
    totalPages: z.number(),
  }),
});

const listUpgradeOptions_Response = listPlans_Response;

export const schemas = {
  analyticsPageview_Body,
  uploadedMediaAuth_Body,
  authPasswordRecover_Body,
  authPasswordReset_Body,
  authLogin_Body,
  authEmailTokenAuth_Body,
  authEmailTokenSignin_Body,
  billingInformationCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_CartItem,
  cartCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_VariationOption,
  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation,
  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation,
  cartVoucherDelete_Body,
  github_com_bamboo_stock_ecommerce_backend_service_RemovedCartItem,
  cartCreateCheckout_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Category,
  github_com_bamboo_stock_ecommerce_backend_service_FilteredClipCategories,
  github_com_bamboo_stock_ecommerce_backend_service_ClipCategory,
  github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist,
  github_com_bamboo_stock_ecommerce_backend_service_Tag,
  github_com_bamboo_stock_ecommerce_backend_service_Clip,
  github_com_bamboo_stock_ecommerce_backend_service_Variation,
  github_com_bamboo_stock_ecommerce_backend_service_CreditCard,
  creditCardsCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_DownloadableVariation,
  github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableClip,
  github_com_bamboo_stock_ecommerce_backend_service_FavoriteProduct,
  filmmakerApplicationCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense,
  github_com_bamboo_stock_ecommerce_backend_service_ProductsSold,
  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerSales,
  marketplacePostbackPagarmev5_Body,
  jobApplicationCreate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_PaymentMethod,
  marketplacePayment_Body,
  github_com_bamboo_stock_ecommerce_backend_service_MarketplaceRecipient,
  marketplaceRecipientCreate_Body,
  marketplaceUserCreate_Body,
  authCreateAccount_Body,
  github_com_bamboo_stock_ecommerce_backend_service_Playlist,
  github_com_bamboo_stock_ecommerce_backend_service_Authorization,
  github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductListing,
  productCreate_Body,
  productUpdate_Body,
  github_com_bamboo_stock_ecommerce_backend_service_GeoLocationView,
  github_com_bamboo_stock_ecommerce_backend_service_PendingTerm,
  github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia,
  uploadedMediaCreate_Body,
  usersGetProfileMe_Body,
};

const endpoints = makeApi([
  {
    method: 'post',
    path: '/api/analytics/pageview',
    alias: 'analyticsPageview',
    description: `Record pageview`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: analyticsPageview_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                anpvID: z.number(),
                createdAt: z.string(),
                fingerprint: z.string(),
                path: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth-media',
    alias: 'uploadedMediaAuth',
    description: `Authenticate media server access`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: uploadedMediaAuth_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/auth/flow/:provider',
    alias: 'auth3rdParty',
    description: `Login with a providers Oauth flow`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'provider',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'applID',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/auth/flow/:provider/callback',
    alias: 'auth3rdParty',
    description: `Login with a providers Oauth flow`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'provider',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'state',
        type: 'Query',
        schema: z.string(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/one-time-login',
    alias: 'authOneTimeLogin',
    description: `Authenticate with single use token`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ token: z.string() }).passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/password-recover',
    alias: 'authPasswordRecover',
    description: `Start password recovery for account with given email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authPasswordRecover_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acveID: z.string(),
                createdAt: z.string(),
                type: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/password-reset/:resetID',
    alias: 'authPasswordReset',
    description: `Reset password for account with given email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authPasswordReset_Body,
      },
      {
        name: 'resetID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z.object({}).partial().passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/password-reset/:resetID/validate',
    alias: 'authPasswordResetValidate',
    description: `Verify that password reset is still valid`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authPasswordReset_Body,
      },
      {
        name: 'resetID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ isValid: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/auth/refresh',
    alias: 'authRefresh',
    description: `Generate new short-lived JWT for current session user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
                plan: userPlanSubscription_schema.nullable(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/signin',
    alias: 'authLogin',
    description: `Login with email &amp; password`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authLogin_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
                plan: userPlanSubscription_schema.nullable(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/signout',
    alias: 'authSignout',
    description: `Signout and forget session and refresh tokens`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/token-auth',
    alias: 'authEmailTokenAuth',
    description: `Start authentication with token for given email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authEmailTokenAuth_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                acveID: z.string(),
                createdAt: z.string(),
                type: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/token-signin',
    alias: 'authEmailTokenSignin',
    description: `Authentication with token for given email`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authEmailTokenSignin_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/auth/validate-email',
    alias: 'authValidateEmail',
    description: `checks if the email is valid for appl_id`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authEmailTokenAuth_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                email: z.string(),
                isValid: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/billing-information',
    alias: 'billingInformationGet',
    description: `Get billing information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string(),
                billingAddress: z
                  .object({
                    cep: z.string(),
                    city: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                  })
                  .passthrough(),
                billingPhone: z
                  .object({ ddd: z.string(), number: z.string() })
                  .passthrough(),
                companyName: z.string().nullish(),
                createdAt: z.string(),
                createdBy: z.string(),
                dob: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                email: z.string(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/billing-information',
    alias: 'billingInformationCreate',
    description: `Create billing information`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: billingInformationCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string(),
                billingAddress: z
                  .object({
                    cep: z.string(),
                    city: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                  })
                  .passthrough(),
                billingPhone: z
                  .object({ ddd: z.string(), number: z.string() })
                  .passthrough(),
                companyName: z.string().nullish(),
                createdAt: z.string(),
                createdBy: z.string(),
                dob: z.string().nullish(),
                documentNumber: z.string(),
                documentType: z.string(),
                email: z.string(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart',
    alias: 'cartCreate',
    description: `Create a new cart to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/cart-voucher',
    alias: 'cartVoucherDelete',
    description: `Remove the voucher from the cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartVoucherDelete_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({ cartID: z.string(), voucID: z.number() })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart-voucher',
    alias: 'cartVoucherCreate',
    description: `validates and links a voucher to the cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartVoucherDelete_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                code: z.string(),
                priceRules: z
                  .object({
                    discountAmount: z.string().nullable(),
                    discountPercentage: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                voucID: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/cart/:cartID',
    alias: 'cartDeleteItem',
    description: `Remove product to cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ caprID: z.number() }).passthrough(),
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/cart/:cartID',
    alias: 'cartGet',
    description: `Get cart by cartID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart/:cartID',
    alias: 'cartCreateItem',
    description: `Add product to cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartCreate_Body,
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/cart/:cartID',
    alias: 'cartSetUser',
    description: `Set cart userID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                userID: z.string().nullish(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart/:cartID/checkout',
    alias: 'cartCreateCheckout',
    description: `Create a new checkout to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: cartCreateCheckout_Body,
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string().nullish(),
                billingInformation: z
                  .object({ email: z.string(), name: z.string() })
                  .passthrough()
                  .nullish(),
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checID: z.number(),
                createdAt: z.string(),
                info: z
                  .object({
                    companyName: z.string().nullable(),
                    orderOfService: z.string().nullable(),
                    projectName: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                invoID: z.string(),
                payment: z
                  .object({
                    createdAt: z.string(),
                    methodCode: z.string(),
                    methodName: z.string(),
                    methodURL: z.string(),
                    paymID: z.string(),
                    price: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                state: z.string(),
                status: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/cart/:checID/checkout',
    alias: 'cartGetCheckout',
    description: `Get checkout by checID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'checID',
        type: 'Path',
        schema: z.number(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                biinID: z.string().nullish(),
                billingInformation: z
                  .object({ email: z.string(), name: z.string() })
                  .passthrough()
                  .nullish(),
                cartID: z.string(),
                cartProducts: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartProductInformation
                ),
                cartVouchers: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_CartVoucherInformation
                ),
                checID: z.number(),
                createdAt: z.string(),
                info: z
                  .object({
                    companyName: z.string().nullable(),
                    orderOfService: z.string().nullable(),
                    projectName: z.string().nullable(),
                  })
                  .partial()
                  .passthrough(),
                invoID: z.string(),
                payment: z
                  .object({
                    createdAt: z.string(),
                    methodCode: z.string(),
                    methodName: z.string(),
                    methodURL: z.string(),
                    paymID: z.string(),
                    price: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                state: z.string(),
                status: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/cart/validate/:cartID',
    alias: 'cartValidate',
    description: `validates and removes invalid items from the cart`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'cartID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_RemovedCartItem
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/categories',
    alias: 'categoryList',
    description: `List all categories to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'notParentSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'parentID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'slug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'parentSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Category
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clip-categories',
    alias: 'clipCategoryList',
    description: `List all categories to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'cateID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'plliID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'searchText',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'collID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'categoryName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'collectionSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'categorySlug',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FilteredClipCategories
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clips',
    alias: 'clipsListClips',
    description: `List all clips to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'categoryName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'excludeProdIDs',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'collectionSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'plliID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'playlistSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[asc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[desc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'collID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'cateID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'searchText',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'categorySlug',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Clip
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/v2/clips',
    alias: 'clipsListClipsV2',
    description: `List all clips to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'categoryName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'excludeProdIDs',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'collectionSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'plliID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'playlistSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[asc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[desc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'playlistName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'collID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'makerSlug',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagName',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'cateID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'searchText',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'tagID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'categorySlug',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'filmID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'suggested',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ClipV2
            ),
            categories: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Clip_CategoriesV2
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/v2/clips-recommended',
    alias: 'clipsListRecommendedClips',
    description: `List all clips to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'searchText',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'relatedWithProdIDs',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'excludeProdIDs',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ClipV2
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/v2/clips/:slug',
    alias: 'clipsGetClipV2',
    description: `Get one clip by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'recommendedOffset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'recommendedLimit',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: github_com_bamboo_stock_ecommerce_backend_service_Get_ClipV2,
            recommended: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_ClipV2
            ),
            categories: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Clip_CategoriesV2
            ),
            kind: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clips/:slug',
    alias: 'clipsGetClip',
    description: `Get one clip by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                categories: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_ClipCategory
                ),
                createdAt: z.string(),
                description: z.string(),
                filmID: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                license: z.array(z.string()),
                makerAvatar: z.string(),
                makerDisplayName: z.string(),
                makerName: z.string(),
                makerSlug: z.string(),
                playlists: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_ClipPlaylist
                ),
                price: z.number(),
                prinID: z.number(),
                prodID: z.string(),
                sku: z.string(),
                slug: z.string(),
                tags: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_Tag
                ),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                title: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/clips/:slug/variations',
    alias: 'clipsGetClipVariations',
    description: `Get clip variations by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                price: z.number(),
                prodID: z.string(),
                slug: z.string(),
                variations: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_Variation
                ),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/collection/:slug',
    alias: 'collectionGet',
    description: `Get one collection by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                collID: z.number(),
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/credit-cards',
    alias: 'creditCardsList',
    description: `Lists all the user&#x27;s credit cards`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_CreditCard
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/credit-cards',
    alias: 'creditCardsCreate',
    description: `Create a new credit card to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: creditCardsCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                billingAddress: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    name: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                    zipcode: z.string(),
                  })
                  .passthrough(),
                brand: z.string(),
                crcaID: z.string(),
                createdAt: z.string(),
                expiresAt: z.string(),
                name: z.string(),
                provider: z.string(),
                providerCard: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/credit-cards/:crcaID',
    alias: 'creditCardsRemove',
    description: `Remove one credit card by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'crcaID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                billingAddress: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    name: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                    zipcode: z.string(),
                  })
                  .passthrough(),
                brand: z.string(),
                crcaID: z.string(),
                createdAt: z.string(),
                expiresAt: z.string(),
                name: z.string(),
                provider: z.string(),
                providerCard: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/credit-cards/:crcaID',
    alias: 'creditCardsGet',
    description: `Get one credit card by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'crcaID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                billingAddress: z
                  .object({
                    city: z.string(),
                    country: z.string(),
                    name: z.string(),
                    neighborhood: z.string(),
                    state: z.string(),
                    street: z.string(),
                    streetNumber: z.string(),
                    zipcode: z.string(),
                  })
                  .passthrough(),
                brand: z.string(),
                crcaID: z.string(),
                createdAt: z.string(),
                expiresAt: z.string(),
                name: z.string(),
                provider: z.string(),
                providerCard: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/downloadable/clips',
    alias: 'downloadableListClips',
    description: `List all downloadable clips`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'checkoutState',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'checID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'paymentStatus',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'checkoutStatus',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_UserDownloadableClip
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/favorite-product',
    alias: 'favoriteProductList',
    description: `List all favorite products`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FavoriteProduct
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/favorite-product',
    alias: 'favoriteProductCreate',
    description: `Create a new favorite product`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ prodID: z.string() }).passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                faprID: z.number(),
                prodID: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/favorite-product/:prodID',
    alias: 'favoriteProductDelete',
    description: `Delete a favorite product`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                faprID: z.number(),
                prodID: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/favorite-product/clips',
    alias: 'favoriteProductListClips',
    description: `List all favorite clips`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'order[asc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'order[desc]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Clip
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/filmmaker-applications',
    alias: 'filmmakerApplicationCreate',
    description: `Create a new filmmaker application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: filmmakerApplicationCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmaker-profile/:slug',
    alias: 'filmmakerProfileGet',
    description: `Get one filmmaker by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                avatar: z.string().nullish(),
                city: z.string(),
                clips: z.number(),
                description: z.string(),
                displayName: z.string(),
                filmID: z.string(),
                headerImage: z.string().nullish(),
                slug: z.string(),
                tags: z.array(z.string()).nullish(),
                userID: z.string(),
                visibility: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmaker/dashboard',
    alias: 'dashboardFilmmakerLicenses',
    description: `Get filmmaker dashboard lincensing info`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                filmID: z.string(),
                listDailySale: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_DashboardAmountLicense
                ),
                totalSales: z.number(),
                totalValue: z.number(),
                totalViews: z.number(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/filmmaker/sales',
    alias: 'filmmakerListSales',
    description: `List all filmmaker sales`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'checID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FilmmakerSales
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/integrations/payment/pagarme/transaction-postback/:paymID/:verification',
    alias: 'marketplacePostbackPagarme',
    description: `Callback pagarme api`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'paymID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'verification',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/integrations/payment/pagarmev5/transaction-postback/:verification',
    alias: 'marketplacePostbackPagarmev5',
    description: `Callback pagarme api v5`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplacePostbackPagarmev5_Body,
      },
      {
        name: 'verification',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/job-applications',
    alias: 'jobApplicationCreate',
    description: `Create a new job application`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: jobApplicationCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/payment-methods',
    alias: 'marketplaceListPaymentMethods',
    description: `List marketplace payment methods`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_PaymentMethod
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/payments',
    alias: 'marketplacePayment',
    description: `Create marketplace payment`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplacePayment_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                invoID: z.string(),
                methodCode: z.string(),
                methodName: z.string(),
                methodURL: z.string(),
                paymID: z.string(),
                status: z.string(),
                userID: z.string(),
                value: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/recipient',
    alias: 'marketplaceRecipientList',
    description: `List marketplace recipient`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_MarketplaceRecipient
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/recipient',
    alias: 'marketplaceRecipientCreate',
    description: `Create marketplace recipient`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplaceRecipientCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bankAccount: z.string(),
                bankAccountDigit: z.string(),
                bankAccountHolderName: z.string(),
                bankAgency: z.string(),
                bankAgencyDigit: z.string(),
                bankCode: z.string(),
                bankDocumentNumber: z.string(),
                bankDocumentType: z.string(),
                bankType: z.string(),
                createdAt: z.string(),
                createdBy: z.string(),
                deletedAt: z.string().nullish(),
                mareID: z.string(),
                method: z.string(),
                methodID: z.string(),
                pixKey: z.string().nullish(),
                transferDay: z.number(),
                transferInterval: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/marketplace/recipient/:mareID',
    alias: 'marketplaceRecipientDelete',
    description: `Remove marketplace recipient by mareID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'mareID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bankAccount: z.string(),
                bankAccountDigit: z.string(),
                bankAccountHolderName: z.string(),
                bankAgency: z.string(),
                bankAgencyDigit: z.string(),
                bankCode: z.string(),
                bankDocumentNumber: z.string(),
                bankDocumentType: z.string(),
                bankType: z.string(),
                createdAt: z.string(),
                createdBy: z.string(),
                deletedAt: z.string().nullish(),
                mareID: z.string(),
                method: z.string(),
                methodID: z.string(),
                pixKey: z.string().nullish(),
                transferDay: z.number(),
                transferInterval: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/recipient/:mareID',
    alias: 'marketplaceRecipientGet',
    description: `Get marketplace recipient by mareID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'mareID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                bankAccount: z.string(),
                bankAccountDigit: z.string(),
                bankAccountHolderName: z.string(),
                bankAgency: z.string(),
                bankAgencyDigit: z.string(),
                bankCode: z.string(),
                bankDocumentNumber: z.string(),
                bankDocumentType: z.string(),
                bankType: z.string(),
                createdAt: z.string(),
                createdBy: z.string(),
                deletedAt: z.string().nullish(),
                mareID: z.string(),
                method: z.string(),
                methodID: z.string(),
                pixKey: z.string().nullish(),
                transferDay: z.number(),
                transferInterval: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/marketplace/user',
    alias: 'marketplaceUserGet',
    description: `Get marketplace user by mausID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string(),
                    city: z.string(),
                    complement: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough(),
                createdAt: z.string(),
                documentNumber: z.string(),
                documentType: z.string(),
                holderName: z.string(),
                mausID: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/marketplace/user',
    alias: 'marketplaceUserCreate',
    description: `Create marketplace user`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: marketplaceUserCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                address: z
                  .object({
                    address: z.string(),
                    city: z.string(),
                    complement: z.string(),
                    zipCode: z.string(),
                  })
                  .passthrough(),
                createdAt: z.string(),
                documentNumber: z.string(),
                documentType: z.string(),
                holderName: z.string(),
                mausID: z.string(),
                updatedAt: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/newsletters-registration',
    alias: 'newslettersRegistrationCreate',
    description: `Create a new newsletters registration to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ email: z.string() }).passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.string(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/onboarding/create-account',
    alias: 'authCreateAccount',
    description: `Create a new account`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: authCreateAccount_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                jwt: z.string(),
                roles: z.array(z.string()),
                user: z
                  .object({
                    applID: z.string(),
                    avatar: z.string().nullish(),
                    createdAt: z.string(),
                    deletedAt: z.string().nullish(),
                    info: z.object({}).partial().passthrough(),
                    name: z.string(),
                    pushTokens: z.array(z.string()).nullish(),
                    userID: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/playlists',
    alias: 'playlistList',
    description: `List all playlists to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'afterID',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'name',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_Playlist
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/playlists/:slug',
    alias: 'playlistGet',
    description: `Get one playlist by slug`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                description: z.string(),
                image: z.string().nullish(),
                name: z.string(),
                parentID: z.number().nullish(),
                plliID: z.number(),
                shortDescription: z.string(),
                slug: z.string(),
                sortOrder: z.number().nullish(),
                thumbnail: z.string().nullish(),
                visible: z.boolean(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product',
    alias: 'productList',
    description: `Lists all the user&#x27;s products`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'createdAt[gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'createdAt[lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'prodID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'upmeID',
        type: 'Query',
        schema: z.array(z.string()).nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'approved',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_FilmmakerProductListing
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product',
    alias: 'productCreate',
    description: `Create a new product to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultraHd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/product/:prodID',
    alias: 'productRemove',
    description: `Remove one product by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultraHd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/product/:prodID',
    alias: 'productGet',
    description: `Get one product by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultraHd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'put',
    path: '/api/product/:prodID',
    alias: 'productUpdate',
    description: `Update one product by ID to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: productUpdate_Body,
      },
      {
        name: 'prodID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                approval: z
                  .object({
                    apprID: z.string(),
                    approved: z.boolean(),
                    createdAt: z.string(),
                    prinID: z.number(),
                    reason: z.string(),
                    status: z.string(),
                  })
                  .passthrough()
                  .nullish(),
                product: z
                  .object({
                    address: z
                      .object({
                        address: z.string().nullable(),
                        city: z.string().nullable(),
                        country: z.string().nullable(),
                        country_short: z.string().nullable(),
                        lat: z.number().nullable(),
                        lng: z.number().nullable(),
                        name: z.string().nullable(),
                        place_id: z.string().nullable(),
                        state: z.string().nullable(),
                        state_short: z.string().nullable(),
                      })
                      .partial()
                      .passthrough(),
                    authorizations: z.array(
                      github_com_bamboo_stock_ecommerce_backend_service_Authorization
                    ),
                    categories: z.array(z.number()),
                    createdAt: z.string(),
                    description: z.string().nullish(),
                    hdUrl: z.string(),
                    information: z
                      .object({
                        codecName: z.string(),
                        duration: z.number(),
                        framerate: z.string(),
                        height: z.number(),
                        width: z.number(),
                      })
                      .passthrough(),
                    license: z.array(z.string()),
                    pendingMedia: z.boolean(),
                    prinID: z.number().nullish(),
                    prodID: z.string().nullish(),
                    tagNames: z.array(z.string()),
                    thumbnailImageUrl: z.string(),
                    thumbnailVideoUrl: z.string(),
                    title: z.string().nullish(),
                    ultraHd4kUrl: z.string(),
                    updatedAt: z.string(),
                    upmeID: z.string(),
                    visible: z.boolean(),
                    watermarkPreviewUrl: z.string(),
                  })
                  .passthrough(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/product/approval',
    alias: 'productCreateApproval',
    description: `Create a new approval to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ prodID: z.string() }).passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                apprID: z.string(),
                approved: z.boolean(),
                createdAt: z.string(),
                prinID: z.number(),
                reason: z.string(),
                status: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/public-auth-media',
    alias: 'mideaServerPublicAuth',
    description: `Public authenticate media server access`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z.object({ jwt: z.string() }).passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/search/geo-locations',
    alias: 'geoLocationList',
    description: `Return a search of GeoLocationView`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'search',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'cep',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_GeoLocationView
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/term/:slug',
    alias: 'termGet',
    description: `Get one term`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'slug',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                description: z.string(),
                slug: z.string(),
                termID: z.number(),
                title: z.string(),
                type: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/term/accept',
    alias: 'termAccept',
    description: `Accept a term`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({ termID: z.number() }).passthrough(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                accepted: z.boolean(),
                termID: z.number(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/term/pending',
    alias: 'termGetPending',
    description: `get all pending terms`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                terms: z.array(
                  github_com_bamboo_stock_ecommerce_backend_service_PendingTerm
                ),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-media',
    alias: 'uploadedMediaList',
    description: `List all uploaded video to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'uploadedAt[Lte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'limit',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'offset',
        type: 'Query',
        schema: z.number().nullish(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'linkedToProduct',
        type: 'Query',
        schema: z.boolean().nullish(),
      },
      {
        name: 'uploadedByApplID',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedBy',
        type: 'Query',
        schema: z.string().nullish(),
      },
      {
        name: 'uploadedAt[Gte]',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            currentItemCount: z.number(),
            items: z.array(
              github_com_bamboo_stock_ecommerce_backend_service_UploadedMedia
            ),
            itemsPerPage: z.number(),
            kind: z.string(),
            lang: z.string().nullish(),
            pageIndex: z.number(),
            startIndex: z.number(),
            totalItems: z.number(),
            totalPages: z.number(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/uploaded-media',
    alias: 'uploadedMediaCreate',
    description: `Create a new uploaded video to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: uploadedMediaCreate_Body,
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'delete',
    path: '/api/uploaded-media/:upmeID',
    alias: 'uploadedMediaRemove',
    description: `Remove one uploaded video by ID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}).partial().passthrough(),
      },
      {
        name: 'upmeID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/uploaded-media/:upmeID',
    alias: 'uploadedMediaGet',
    description: `Get one uploaded video by id to database`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'upmeID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                deletedAt: z.string().nullish(),
                hdURL: z.string(),
                information: z
                  .object({
                    codecName: z.string(),
                    duration: z.number(),
                    framerate: z.string(),
                    height: z.number(),
                    width: z.number(),
                  })
                  .passthrough(),
                thumbnailImageURL: z.string(),
                thumbnailVideoURL: z.string(),
                ultraHd4kURL: z.string(),
                uploadedAt: z.string(),
                uploadedBY: z.string(),
                upmeID: z.string(),
                watermarkPreviewURL: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/users/:userID',
    alias: 'usersGetUserByID',
    description: `Return the authenticated user&#x27;s data`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                pushTokens: z.array(z.string()).nullish(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'patch',
    path: '/api/users/:userID',
    alias: 'usersPatchUserByID',
    description: `Update user&#x27;s data`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: usersGetProfileMe_Body,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                filmmaker: z
                  .object({
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                    headerImage: z.string().nullish(),
                    mareID: z.string().nullish(),
                    slug: z.string(),
                    tags: z.array(z.string()).nullish(),
                    userID: z.string(),
                    visibility: z.boolean(),
                  })
                  .passthrough()
                  .nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/users/profile/:userID',
    alias: 'usersGetProfileMe',
    description: `Get user profile`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: usersGetProfileMe_Body,
      },
      {
        name: 'userID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                applID: z.string(),
                avatar: z.string().nullish(),
                createdAt: z.string(),
                deletedAt: z.string().nullish(),
                email: z.string().nullish(),
                filmID: z.string().nullish(),
                filmmaker: z
                  .object({
                    description: z.string(),
                    displayName: z.string(),
                    filmID: z.string(),
                    headerImage: z.string().nullish(),
                    mareID: z.string().nullish(),
                    slug: z.string(),
                    tags: z.array(z.string()).nullish(),
                    userID: z.string(),
                    visibility: z.boolean(),
                  })
                  .passthrough()
                  .nullish(),
                info: z.object({}).partial().passthrough(),
                name: z.string(),
                userID: z.string(),
              })
              .passthrough(),
            kind: z.string(),
            lang: z.string().nullish(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'post',
    path: '/api/v1/license/purchase',
    alias: 'purchaseLicenses',
    description: `purchase list of licenses`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: purchaseLicenses_Body,
      },
    ],
    response: purchaseLicenses_Response,
  },
  {
    method: 'post',
    path: '/api/v1/subscription',
    alias: 'subscribeToPlan',
    description: `subscribe to a plan`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: createSubscription_Body,
      },
    ],
    response: createSubscription_Response,
  },
  {
    method: 'post',
    path: '/api/v1/subscription/change',
    alias: 'changeSubscriptionPlan',
    description: `subscribe to a different plan`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: changeSubscription_Body,
      },
    ],
    response: changeSubscription_Response,
  },
  {
    method: 'delete',
    path: '/api/v1/subscription/:subscriptionID',
    alias: 'cancelSubscription',
    description: `cancel subscription`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}),
      },
    ],
    response: z.any(),
  },
  {
    method: 'post',
    path: '/api/v2/credit-cards',
    alias: 'createCreditCard',
    description: `create new credit card`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: createCreditCardV2_Body,
      },
    ],
    response: createCreditCardV2_Response,
  },
  {
    method: 'post',
    path: '/api/v2/credit-cards/subscription/:subscriptionID',
    alias: 'createCreditCardAsSubscriptionPaymentMethod',
    description: `create new credit card associating it to an existing subscription`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: createCreditCardV2_Body,
      },
    ],
    response: createCreditCardV2_Response,
  },
  {
    method: 'get',
    path: '/api/v2/credit-cards',
    alias: 'listCreditCards',
    description: `List credit cards`,
    requestFormat: 'json',
    parameters: [],
    response: getCredtCardsV2_Response,
  },
  {
    method: 'get',
    path: '/api/v1/plan',
    alias: 'listPlans',
    description: `List available plans`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'level',
        type: 'Query',
        schema: z.number().nullish(),
      },
    ],
    response: listPlans_Response,
  },
  {
    method: 'get',
    path: '/api/v1/plan/:planID',
    alias: 'getPlanByID',
    description: `getPlanByID`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'planID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: getPlanByID_Response,
  },
  {
    method: 'get',
    path: '/api/v1/plan/upgrade-options',
    alias: 'listUpgradeOptions',
    description: `List available plans to upgrade`,
    requestFormat: 'json',
    parameters: [],
    response: listUpgradeOptions_Response,
  },
  {
    method: 'get',
    path: '/api/v2/downloadable/remaining',
    alias: 'getRemainingClips',
    description: `getRemainingClips`,
    requestFormat: 'json',
    parameters: [],
    response: z
      .object({
        context: z.string().nullish(),
        data: z
          .object({
            item: z
              .object({
                remaining: z.number(),
              })
              .passthrough(),
          })
          .passthrough(),
      })
      .passthrough(),
  },
  {
    method: 'get',
    path: '/api/v1/subscription',
    alias: 'getSubscriptionInfo',
    description: `getRemainingClips`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'context',
        type: 'Query',
        schema: z.string().nullish(),
      },
    ],
    response: z.object({
      data: z.object({
        kind: z.string(),
        item: z.object({
          plan: z.object({
            planID: z.number(),
            name: z.string(),
            level: z.number(),
            annual: z.boolean(),
          }),
          clipsRemaining: z.number(),
          subscription: z.object({
            subscriptionID: z.string(),
            status: z.string(),
            createdAt: z.string(),
          }),
          period: z.object({
            currentPeriodStartedAt: z
              .object({
                Time: z.string(),
                Valid: z.boolean(),
              })
              .nullable()
              .transform((val) => {
                if (!val) return null;
                return val.Time;
              }),
            currentPeriodEndAt: z
              .object({
                Time: z.string(),
                Valid: z.boolean(),
              })
              .nullable()
              .transform((val) => {
                if (!val) return null;
                return val.Time;
              }),
          }),
          card: z.object({
            creditCardLastFour: z.string(),
            creditCardBrand: z.string(),
          }),
          features: z.object({
            clipsLimit: z.number(),
            enable4K: z.boolean(),
            enableTvLicense: z.boolean(),
            ilimitedLicenses: z.boolean(),
          }),
        }),
      }),
    }),
  },
  {
    method: 'get',
    path: '/api/v1/subscription/:subscriptionID/bill',
    alias: 'getSubscriptionBills',
    description: `getSubscriptionBills`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionID',
        type: 'Path',
        schema: z.string(),
      },
    ],
    response: z.object({
      data: z.object({
        kind: z.string(),
        items: z.array(
          z.object({
            billID: z.string(),
            amount: z.string(),
            createdAt: z.string(),
            status: z.string(),
            refundedAt: z
              .object({ Time: z.string(), Valid: z.boolean() })
              .nullable()
              .transform((val) => (val ? val.Time : null)),
            canceledAt: z
              .object({ Time: z.string(), Valid: z.boolean() })
              .nullable()
              .transform((val) => (val ? val.Time : null)),
          })
        ),
        currentItemCount: z.number(),
        itemsPerPage: z.number(),
        startIndex: z.number(),
        totalItems: z.number(),
        pageIndex: z.number(),
        totalPages: z.number(),
      }),
    }),
  },
  {
    method: 'post',
    path: '/api/v1/subscription/:subscriptionID/bill',
    alias: 'createNewSubscriptionBill',
    description: `createNewSubscriptionBill`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'subscriptionID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}),
      },
    ],
    response: z.any(),
  },
  {
    method: 'post',
    path: '/api/v1/bill/:billID/retry-charge',
    alias: 'retrySubscriptionBillCharge',
    description: `retrySubscriptionBillCharge`,
    requestFormat: 'json',
    parameters: [
      {
        name: 'billID',
        type: 'Path',
        schema: z.string(),
      },
      {
        name: 'body',
        description: `Request data`,
        type: 'Body',
        schema: z.object({}),
      },
    ],
    response: z.any(),
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
